
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import Calendar from "@/components/calendar/Calendar.vue";

export default defineComponent({
  name: "parent-Calendar",
  components: {
    Calendar,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref(true);

    onMounted(() => {
      setCurrentPageTitle(t("calendarApp"));
    });

    return {
      t,
      moment,
      loading,
    };
  },
});
