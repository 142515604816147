
import { defineComponent, onMounted, ref } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";

import { Modal } from "bootstrap";
import moment from "moment";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "calendar-advisor-meeting",
  components: {
    FullCalendar,
  },
  setup() {
    const calendar = ref();
    const { t } = useI18n();

    const selectedEvent = ref<any>({});
    const calendarLoading = ref(true);

    // eslint-disable-next-line
    const meetings = ref<any[]>([]);
    // eslint-disable-next-line
    const events = ref<any[]>([]);

    const meetingInfo = (info) => {
      const record = info.event._def.extendedProps;
      const modal = new Modal(document.getElementById("kt_modal_event_info"));
      modal.show();
      selectedEvent.value = events.value.find((e) => e._id == record._id);
    };

    const studentID = window.localStorage.getItem("activeStudent");

    const requestMeetings = async () => {
      await ApiService.get("/events/parent")
        .then(({ data }) => {
          events.value = data;
        })
        .catch((e) => {
          console.log(e);
        });
      await ApiService.get("/events/parent/student/" + studentID)
        .then(({ data }) => {
          events.value = [...events.value, ...data];

          calendarLoading.value = false;
          setTimeout(() => {
            calendar.value.getApi().refetchEvents();
          }, 50);
        })
        .catch((e) => {
          console.log(e);
        });
    };

    onMounted(async () => {
      await requestMeetings();
    });

    function getMeetingData(info, successCallback) {
      // eslint-disable-next-line
      const data: any[] = [];
      events.value.forEach((event) => {
        data.push({
          _id: event._id,
          model: "event",
          title: `${
            event.type != "other"
              ? "(" + t("calendar." + event.type) + ") "
              : ""
          } ${event.title}`,
          start: moment(event.start).format("YYYY-MM-DD[T]HH:mm:ss"),
          end: moment(event.end).format("YYYY-MM-DD[T]HH:mm:ss"),
          description: event.desc,
          className: "fc-event-danger fc-event-solid-warning",
          color: event.color,
        });
      });
      successCallback(data);
    }

    const calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
      headerToolbar: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      },
      //  initialDate: moment().startOf("day"),
      navLinks: true, // can click day/week names to navigate views
      selectable: true,
      selectMirror: true,

      views: {
        dayGridMonth: { buttonText: "month" },
        timeGridWeek: { buttonText: "week" },
        timeGridDay: { buttonText: "day" },
      },

      editable: false,
      dayMaxEvents: true, // allow "more" link when too many events
      events: getMeetingData,
      eventClick: meetingInfo,
    };

    return {
      calendarOptions,
      calendar,
      t,
      selectedEvent,
      moment,
      calendarLoading,
    };
  },
});
